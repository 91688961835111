import React from 'react';
import { ButtonStyles } from './button.styles';

interface Props extends React.ButtonHTMLAttributes<any> {
  children: React.ReactNode;
  loading?: boolean
}

export function Button({ children, loading, style, ...other }: Props) {
  return (
    <ButtonStyles {...other}>
      {loading && (
        <div className="btn-loader" />
      )}
      <div style={{...style, opacity: loading ? 0 : 1}}>
        {children}
      </div>
    </ButtonStyles>
  );
}