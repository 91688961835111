import styled from 'styled-components';

export const ProductsStyles = styled.div`
  & .products-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin: 16px 0 20px 0;
  }
  
  & .button-load-more {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
  }
`

export const ProductTopBar = styled.div`
  margin-top: 15px;
  margin-bottom: 70px;
  transition: 0.3s ease;
  display: flex;
  width: 100%;
  justify-content: center;

  & input {
    outline: none;
    border: 1px solid #cccccc;
    padding: 12px;
    border-radius: 8px;
    width: 100%;
  }

  & h4 {
    font-size: 14px;
    font-weight: 500;
    color: black;
  }

  &.active {
    margin-top: 0;
    margin-bottom: 0;

    & .search-bar {
      top: 10px;
    }
  }

  & .search-bar {
    position: fixed;
    left: 0;
    top: 50px;
    width: 100%;
    padding: 0 16px;

    & svg {
      position: absolute;
      top: 12px;
      left: 28px;
    }

    & input {
      padding-left: 36px;
    }

    & .search-bar-fixed {
      display: flex;
      justify-content: center;
      position: relative;
    }

    & .search-bar-list {
      position: absolute;
      width: 100%;
      background: white;
      z-index: 99;
      border-radius: 8px;
      top: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      padding: 12px;
      height: 300px;
      overflow-y: scroll;
      left: 0;

      & p {
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }

      & .skeleton {
        @keyframes loading {
          0% {
            background-position: 200% 0;
          }
          100% {
            background-position: -200% 0;
          }
        }

        & div {
          width: 100%;
          height: 30px;
          background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
          background-size: 200% 100%;
          animation: loading 2s infinite ease-in-out;
          border-radius: 6px;
          margin-bottom: 10px;
        }
      }
    }

    & .not-found-message {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;

      & h4 {
        line-height: 20px;
      }
    }
  }
`

export const FilterButton = styled.button`
  background: #3793FF;
  padding: 6px 12px 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  
  & svg {
    margin-right: 5px;
  }
`

export const OrderButton = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  
  & button {
    width: 100%;
    border-radius: 0;
    padding: 16px;
  }
  
  & svg {
    transform: rotate(-90deg);
    position: relative;
    top: 2px;
  }

  @keyframes bump {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.03);
    }
    100% {
      transform: scale(1);
    }
  }

  &.animate-bump {
    animation: bump 0.3s ease-in-out;
  }
`

export const ProductEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & h3 {
    background: rgba(164, 164, 164, 0.3);
    padding: 3px 5px;
    font-size: 13px;
    border-radius: 10px;
  }

`

export const Pagination = styled.div`
  margin-bottom: 100px;
  margin-top: 70px;
  display: flex;
  justify-content: center;

  position: relative;

  .pagination {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
  }

  .disabled-pagination {
    pointer-events: none;
    opacity: .4;
  }

  .page-item {
    width: 30px;
    height: 30px;
    background: #b7b7b7;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: #a6a6a6;
    }

    & a {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  .previous-icon {
    transform: rotate(180deg);
  }

  .active {
    background: #007bff;

    &:hover {
      background: #007bff;
    }
  }

  .see-more-button {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
  }
`