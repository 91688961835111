import * as yup from 'yup';

export const defaultValues: any = {
  firstName: '',
  phoneNumber: '',
  address: '',
  comment: '',
  isDelivery: true,
  isPaymentOnline: true,
  dateShipping: '',
  timeShipping: '',
};

const today = new Date();
today.setHours(0, 0, 0, 0);

export const schema = yup.object().shape({
  firstName: yup.string()
    .matches(/^[a-zA-Zа-яА-ЯёЁ\s]*$/, 'Разрешены только буквы.')
    .required('Это поле обязательно к заполнению'),
  phoneNumber: yup.string().required('Это поле обязательно к заполнению').min(9, 'пожалуйста, введите полностью'),
  address: yup.string().required('Это поле обязательно к заполнению'),
  comment: yup.string().required('Это поле обязательно к заполнению'),
  // dateShipping: yup .date()
  //   .transform((value, originalValue) => (originalValue === "" ? null : value))
  //   .nullable()
  //   .required('Это поле обязательно к заполнению')
  //   .min(today, 'Выберите дату не ранее сегодняшнего дня'),
  // timeShipping: yup.string()
  //   .matches(/^\d{4}$/, 'Это поле обязательно к заполнению')
  //   .test('valid-hours', 'Часы не могут быть больше 23.', (value ) => {
  //     const number = value ?? ''
  //     const hours = parseInt(number.slice(0, 2), 10);
  //     return hours <= 23;
  //   })
  //   .test('valid-minutes', 'Минуты не могут быть больше 59.', (value) => {
  //     const number = value ?? ''
  //     const minutes = parseInt(number.slice(2, 4), 10);
  //     return minutes <= 59;
  //   })
  //   .required('Это поле обязательно к заполнению'),
});